// hooks/usePrivilege.js
import { useUser } from '@auth0/nextjs-auth0';
import { navBarMenuItems } from '../static';

const usePrivilege = () => {
	const { user } = useUser();

	const findMenuItemByHref = (href) => {
		return navBarMenuItems.find((item) => item.href === href);
	};

	// Check if the user has a specific privilege
	const hasPrivilege = (requiredPrivilege) => {
		if (user?.group === 'developer') return true;
		return user?.privileges.includes(requiredPrivilege);
	};

	// Check if the user has all of the specified privileges
	const hasAllPrivileges = (requiredPrivileges = []) => {
		if (user?.group === 'developer') return true;
		return requiredPrivileges.every((priv) => user?.privileges.includes(priv));
	};

	// Check if the user has any of the specified privileges
	const hasAnyPrivilege = (requiredPrivileges = []) => {
		if (user?.group === 'developer') return true;
		return requiredPrivileges.some((priv) => user?.privileges.includes(priv));
	};

	const canReadBatches = () => {
		return hasAnyPrivilege(['batch_mgmt', 'batch_super', 'batch_manual', 'read_only_batches']);
	};

	const canWriteBatches = () => {
		return hasAnyPrivilege(['batch_mgmt', 'batch_super', 'batch_manual']);
	};

	const canReadApprovals = () => {
		return hasAnyPrivilege(['initial_approve', 'final_approve', 'read_only_approvers']);
	};

	const canWriteApprovals = () => {
		return hasAnyPrivilege(['initial_approve', 'final_approve']);
	};

	const canReadPayments = () => {
		return true;
	};

	const canReadDeposits = () => {
		return true;
	};

	const canWriteDeposits = () => {
		return true;
	};

	const canReadBai = () => {
		return hasAnyPrivilege(['bai_workflows']);
	};

	const canWriteBai = () => {
		return hasAnyPrivilege(['bai_workflows']);
	};

	const userOnRestrictedPage = (pathname) => {
		if (pathname === '/' && !canReadApprovals()) return true;
		if (pathname === '/batch' && !canReadBatches()) return true;
		if (pathname === '/payments' && !canReadPayments()) return true;
		if (pathname === '/deposits' && !canReadDeposits()) return true;
		if (pathname === '/bai' && !canReadBai()) return true;
		return false;
	};

	const buildNavBar = () => {
		const visibleMenuItems = [];

		visibleMenuItems.push(navBarMenuItems[1]);

		if (canReadApprovals()) {
			visibleMenuItems.push(findMenuItemByHref('/'));
		}

		if (canReadBatches()) {
			visibleMenuItems.push(findMenuItemByHref('/batch'));
		}

		if (canReadDeposits()) {
			visibleMenuItems.push(findMenuItemByHref('/deposits'));
		}

		if (canReadPayments()) {
			visibleMenuItems.push(findMenuItemByHref('/payments'));
		}

		if (canReadBai()) {
			visibleMenuItems.push(navBarMenuItems[5]);
		}

		return visibleMenuItems;
	};

	return {
		hasPrivilege,
		hasAllPrivileges,
		hasAnyPrivilege,
		canReadBatches,
		canWriteBatches,
		canReadApprovals,
		canWriteApprovals,
		canReadPayments,
		canReadDeposits,
		canReadBai,
		canWriteBai,
		userOnRestrictedPage,
		buildNavBar
	};
};

export default usePrivilege;
