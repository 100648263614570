import { useState } from 'react';
import { Modal } from 'antd';
import { InformationCircleIcon, ExclamationCircleIcon, PlusIcon } from '@heroicons/react/solid';
import { QuestionMarkCircleIcon } from '@heroicons/react/outline';

export default function HelpModal(props) {
	const [modalOpen, setModalOpen] = useState(false);

	return (
		<>
			<Modal
				title="Need Help?"
				open={modalOpen}
				size="large"
				width={600}
				closeIcon={null}
				onCancel={() => {
					setModalOpen(!modalOpen);
				}}
				onOk={(e) => {
					e.preventDefault();
					setModalOpen(!modalOpen);
				}}
			>
				<p className="mb-0 text-lg font-bold">Search & Filter</p>
				<p className="text-sm">
					Use the Search bar to search across multiple invoice attributes. You can also click on the funnel in
					the column headers to filter in a more excel-esque format.
				</p>
				<p className="mt-2 mb-0 text-lg font-bold">Sorting</p>
				<p className="text-sm">Use the arrows in the column headers to sort by specific columns.</p>
				<p className="text-sm font-semibold">
					NOTE:
					<span className="italic"> Sorting by mulitple columns simultaneously is not yet supported.</span>
				</p>
				<p className="text-lg mt-3 font-bold">Helpful Icons</p>
				<div className="flex-col">
					<span className="inline-flex items-center">
						<ExclamationCircleIcon className="w-10 h-10 text-red-500 hover:cursor-pointer" />
						<p className="ml-2 font-semibold">HIGH PRIORITY ITEM</p>
					</span>
					<span className="inline-flex items-center">
						<InformationCircleIcon className="w-10 h-10 text-yellow-500 hover:cursor-pointer" />
						<p className="ml-2 font-semibold">
							Invoice contains special handling instructions. Hover to view the instructions!
						</p>
					</span>
					<span className="inline-flex items-center">
						<PlusIcon className="w-10 h-10 hover:cursor-pointer border-2 border-slate-200 text-slate-700" />
						<p className="ml-2 font-semibold">
							Click to view additional details, such as the Lumen description.
						</p>
					</span>
				</div>
			</Modal>
			<QuestionMarkCircleIcon
				className="h-6 w-6 text-slate-300 hover:text-white drop-shadow-xl hover:drop-shadow-none hover:cursor-pointer"
				onClick={() => {
					setModalOpen(true);
				}}
			/>
		</>
	);
}
