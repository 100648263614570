import {
	CheckCircleIcon,
	ClockIcon,
	CogIcon,
	CurrencyDollarIcon,
	DocumentReportIcon,
	ShieldExclamationIcon
} from '@heroicons/react/outline';

export const editableStatus = [
	'Created',
	'Corrected',
	'Currency Conversion',
	'Submitted Conversion',
	'Processed Conversion'
];
export const manualWorkflows = ['M', 'AD', 'IW', 'TOE'];
export const submittedStatus = ['User Submitted', 'Submitted'];
export const completeStatus = ['Posted', 'Complete'];
export const cardDimensions = { width: 300, height: 250 };
export const sendToBankTypes = ['ACH', 'W', 'IW'];
export const currencyConversionStatuses = [
	'Currency Conversion',
	'Submitted Conversion',
	'Processing Conversion',
	'Processed Conversion'
];
export const needsReviewGroup = ['ACH', 'W', 'IW'];
export const navBarMenuItems = [
	{
		key: '1',
		icon: <CheckCircleIcon className="w-8 h-8 mb-2 inline-block" />,
		avatar: <CheckCircleIcon style={{ backgroundColor: 'white' }} />,
		items: null,
		label: 'My Approvals',
		sublabel: null,
		href: '/'
	},
	{
		key: '2',
		icon: <ClockIcon className="w-8 h-8 mb-2 inline-block" />,
		avatar: <ClockIcon style={{ backgroundColor: 'white' }} />,
		items: null,
		label: 'Pending Workflows',
		sublabel: 'Find & view historical items',
		href: '/workflows'
	},
	{
		key: '3',
		icon: <CogIcon className="w-8 h-8 mb-2 inline-block" />,
		avatar: <CogIcon style={{ backgroundColor: 'white' }} />,
		items: null,
		label: 'Batch Management',
		sublabel: 'Schedule batch jobs',
		href: '/batch'
	},
	{
		key: '4',
		icon: <DocumentReportIcon className="w-8 h-8 mb-2 inline-block " />,
		avatar: <DocumentReportIcon style={{ backgroundColor: 'white' }} />,
		items: null,
		label: 'Payments',
		sublabel: 'Completed Payments & Funding Reports',
		href: '/payments'
	},
	{
		key: '5',
		icon: <CurrencyDollarIcon className="w-8 h-8 mb-2 inline-block " />,
		avatar: <CurrencyDollarIcon style={{ backgroundColor: 'white' }} />,
		items: null,
		label: 'Deposits',
		sublabel: 'Deposit Approvals',
		href: '/deposits'
	},
	{
		key: '6',
		icon: <ShieldExclamationIcon className="w-8 h-8 mb-2 inline-block " />,
		avatar: <ShieldExclamationIcon style={{ backgroundColor: 'white' }} />,
		items: null,
		label: 'B.A.I.',
		sublabel: 'Exceptions Reporting',
		href: '/bai'
	}
];
