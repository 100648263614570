import { useUser } from '@auth0/nextjs-auth0';
import { Layout, PageHeader, Select } from 'antd';
const { Header: AntHeader } = Layout;
import Image from 'next/image';
import { useEffect, useState } from 'react';
import BasicHeader from './BasicHeader';

export default function Header({ pageTitle }) {
	const { user } = useUser();
	const [orgOptions, setOrgOptions] = useState();
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		if (user?.orgScope?.length > 0 && !loaded) {
			const tempOrgs = user.orgScope.map((e) => {
				if (e.org == user?.org) {
					return { label: `${e.org}`, value: e.org };
				} else {
					return { label: `${e.org}`, value: e.org };
				}
			});

			setOrgOptions(tempOrgs);
			setLoaded(true);
		}
	}, [orgOptions]);

	const handleOrgChange = async (orgId) => {
		const response = await fetch('/api/toggleOrg', {
			method: 'POST',
			body: JSON.stringify({ orgId })
		});
		if (response.ok) {
			window.location.reload();
		}
	};

	return (
		<AntHeader
			className="site-header-background border-b-2"
			style={{
				padding: 0,
				position: 'fixed',
				zIndex: 100,
				width: '97%',
				height: '75px'
			}}
		>
			<div className="grid grid-cols-2">
				<div>
					{/* <BasicHeader
						icon={pageTitle.icon}
						iconSize='large'
						iconShape='square'
						containerClass='pb-5'
						title={pageTitle.title}
						subTitle={pageTitle.subTitle}
					/> */}
					<PageHeader
						avatar={{
							icon: pageTitle.icon,
							size: 'large',
							shape: 'square'
						}}
						title={pageTitle.title || ''}
						subTitle={pageTitle.subTitle || ''}
						className="site-page-header"
						extra={[]}
						style={{ height: 73 }}
					/>
				</div>
				<div className="text-right pt-1 mr-7 grid justify-end">
					<div className="flex w-full">
						{user?.orgScope?.length > 0 && (
							<div className="flex-grow grid grid-cols-2 justify-end">
								<div className="whitespace-nowrap font-bold mr-4">Current Viewing:</div>
								<div>
									<Select
										mode="multiple"
										size="large"
										className="org-select whitespace-nowrap inline-block"
										placeholder={`${user?.org}`}
										defaultValue={user?.org}
										options={orgOptions}
										onChange={handleOrgChange}
									/>
								</div>
							</div>
						)}
						<div className="text-right w-28">
							<Image key="1" style={{}} src="/images/logo.png" alt="WEI" width="65" height="65" />
						</div>
					</div>
				</div>
			</div>
		</AntHeader>
	);
}
