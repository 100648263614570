import { Layout as AntLayout } from 'antd';
const { Content } = AntLayout;

import Navbar from './Navbar';
import Header from './Header';
import Footer from './Footer';

import { useState } from 'react';

export default function Layout({ children }) {
	const layoutStyle = { minHeight: '100vh' };
	const [pageTitle, setPageTitle] = useState({ title: 'My Approvals', subTitle: null });

	return (
		<AntLayout style={layoutStyle} hasSider>
			<Navbar setPageTitle={setPageTitle} />
			<AntLayout
				className="site-layout"
				style={{
					marginLeft: 80
				}}
			>
				<Header pageTitle={pageTitle} />
				<Content
					className="site-layout-background"
					style={{
						margin: '100px 16px',
						padding: 0,
						minHeight: 280
					}}
				>
					<main>{children}</main>
				</Content>
				<Footer />
			</AntLayout>
		</AntLayout>
	);
}
