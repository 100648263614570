import { Avatar } from 'antd';

export default function BasicHeader(props) {
	return (
		<div className={props.containerClass}>
			{/* icon: pageTitle.icon,
							size: 'large',
							shape: 'square' */}
			<div style={{ display: 'flex' }}>
				{props.icon ? <Avatar size={props.iconSize} shape={props.iconShape} icon={props.icon} /> : null}
				<div className="inline">
					<div className={'basic-header-title'}>{props.title}</div>
					<div className={'basic-sub-title'}>{props.subTitle}</div>
				</div>
			</div>
		</div>
	);
}
