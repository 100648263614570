import { useUser } from '@auth0/nextjs-auth0';
import { LockClosedIcon } from '@heroicons/react/outline';
import { BugForm } from '@wei-technology/react-bug-form';
import { Layout, Menu } from 'antd';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import usePrivilege from '../../lib/hooks/usePrivilege';
import { navBarMenuItems } from '../../lib/static';
const { Sider } = Layout;
import HelpModal from '../ui/HelpModal';

export default function Navbar(props) {
	const { user } = useUser();
	const router = useRouter();
	const [availableMenuItems, setAvailableMenuItems] = useState(navBarMenuItems);
	const [selectedKey, setSelectedKey] = useState('1');
	const { buildNavBar } = usePrivilege();

	const onMenuClick = async (event) => {
		const selectedItem = availableMenuItems.find((x) => x.key === event.key);
		props.setPageTitle({ title: selectedItem.label, subTitle: selectedItem.sublabel, icon: selectedItem.avatar });
		setSelectedKey(selectedItem.key);
		router.push(selectedItem.href);
	};

	useEffect(() => {
		const visibleMenuItems = buildNavBar();

		if (visibleMenuItems.length > 0) {
			visibleMenuItems.sort((a, b) => a.key - b.key);

			setSelectedKey(visibleMenuItems[0].key);
			setAvailableMenuItems(visibleMenuItems);

			const isUserOnRestrictedPage = !visibleMenuItems.some((item) => router.pathname.includes(item.href));
			if (isUserOnRestrictedPage) {
				router.push(visibleMenuItems[0].href);
			}
		}
	}, [router, user.privileges]);

	useEffect(() => {
		const urlParts = router.pathname.split('/');
		const current = availableMenuItems.find((e) => e.href == router.pathname || e.href == `/${urlParts[1] ?? ''}`);
		props.setPageTitle({ title: current.label, subTitle: current.sublabel, icon: current.avatar });
		setSelectedKey(current.key);
	}, [router]);

	return (
		<Sider
			collapsed="true"
			className="nav-bar-container"
			style={{
				overflow: 'hidden',
				height: '100vh',
				position: 'fixed',
				left: 0,
				top: 0,
				bottom: 0
			}}
		>
			<div id="nav-bar-content" className="flex flex-col justify-between h-full">
				<div id="nav-bar-main" className="mx-auto -ml-5 -mt-1">
					<Menu
						className="mx-auto nav-bar-menu"
						items={availableMenuItems}
						selectedKeys={[selectedKey]}
						theme="dark"
						mode="inline"
						onClick={onMenuClick}
						style={{ width: 102 }}
					/>
				</div>
				<div id="nav-bar-footer" className="flex flex-col items-center space-y-4 mb-2">
					<div>
						<HelpModal />
					</div>
					<div>
						<BugForm
							user={{ name: user.name, email: user.email }}
							application="AP Dashboard"
							iconClasses="h-6 w-6 text-slate-300 hover:text-white"
						/>
					</div>
					<div>
						<a
							href="/api/auth/logout"
							title="Logout"
							className="text-slate-300 hover:text-white font-extrabold"
						>
							<LockClosedIcon className="h-6 w-6" />
						</a>
					</div>
				</div>
			</div>

			{/* <div className="relative mx-auto flex -ml-5 -mt-1 space-y-10">
				<Menu
					className="mx-auto nav-bar-menu"
					items={availableMenuItems}
					selectedKeys={[selectedKey]}
					theme="dark"
					mode="inline"
					onClick={onMenuClick}
					style={{ width: 102 }}
				/>
			</div>
			<div className="flex">
				<QuestionMarkCircleIcon className="h-6 w-6 mx-auto text-slate-400 drop-shadow-xl hover:cursor-pointer hover:text-slate-600 hover:drop-shadow-none" />
				<BugForm
					user={{ name: user.name, email: user.email }}
					application="AP Dashboard"
					buttonClasses="pl-7"
					iconClasses="hover:text-white w-6 h-6 text-slate-300"
				/>
				<a
					href="/api/auth/logout"
					title="Logout"
					className="text-slate-300 left-0 mx-auto font-extrabold hover:text-white pt-2 h-12 w-20 flex"
				>
					<LockClosedIcon className="w-6 h-6 mx-auto" />
				</a>
			</div> */}
		</Sider>
	);
}
